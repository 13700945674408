.reports-home {

}

    .reports-home .react-datepicker-wrapper input {
        width: 200px;
    }

    .reports-home .react-datepicker .react-datepicker__triangle {
        transform: translate(120px, 0px) !important;
    }
/* Provide sufficient contrast against white background */
body {
	font-size: 13px;
}

.form-control {
	font-size: 13px;
}

a {
	color: #0366d6;
}

p {
	margin-bottom: 5px;
}

code {
	color: #e01a76;
}

.navbar .container {
	margin-left: 68px;
	margin-right: 0px;
	width: 100%;
	max-width: calc(100% - 10%) !important;
}

.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

#appBody {
	margin: 0px 100px;
}

.form-control {
	padding: 3px 5px !important;
}

.form-control.error {
	border-color: red !important;
}

.form-control.error:focus {
	box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%);
}

input[type="date"] {
	max-width: 300px;
}

/* ===== SCROLLBAR ===== */

.custom-scrollbar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	background-color: #f5f5f5;
	visibility: hidden;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: #f5f5f5;
	visibility: hidden;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	border-radius: 3px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #ccc;
	visibility: hidden;
}

.custom-scrollbar.cs-blue::-webkit-scrollbar-thumb {
	/*background-color: rgba(27, 87, 230, 0.6);*/
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
	visibility: visible;
}

/* ===================== */

/* ===== message box ===== */
.state-message {
	/*font-family: "Montserrat", "Source Sans Pro", "Arvo" !important;*/
	margin: 0 auto;
	margin-top: 0.5%;
	position: fixed;
	top: 20px;
	left: 42.5%;
	width: 15%;
	text-align: center;
	font-weight: bold;
	color: white;
	background-color: rgb(0, 200, 50);
	opacity: 1;
	padding: 20px;
	border-radius: 3px;
	-webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
	z-index: 10000;
}

.state-message.hidden {
	transition: opacity 2s ease-in-out;
	opacity: 0;
}

.state-message .fas {
	position: absolute;
	top: 8px;
	right: 8px;
	cursor: pointer;
}

/* ========================= */

/* ===== POINTED POPUP ===== */

.popup-dialog {
	display: block;
	position: fixed;
	z-index: 5;
	background-color: rgb(230, 230, 230);
	padding: 8px 5px;
	border-radius: 3px;
	border: 1px solid;
	border-color: transparent rgb(230, 230, 230) rgb(230, 230, 230)
		rgb(230, 230, 230);
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
}

.popup-dialog.bottom-pointer {
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
}

.popup-dialog:before {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
	left: 5px;
	top: -21px;
	border: 10px solid;
	border-color: transparent transparent rgb(230, 230, 230) rgb(230, 230, 230);
	/*box-shadow: 0px 0px 5px black;*/
}

.popup-dialog.right-pointer:before {
	left: unset;
	right: 5px;
	top: -21px;
	border-color: transparent rgb(229, 229, 229) rgb(229, 229, 229) transparent;
	/*box-shadow: 0px 0px 5px black;*/
}

.popup-dialog.bottom-pointer:before {
	top: unset;
	bottom: -21px;
	border-color: rgb(229, 229, 229) transparent transparent rgb(229, 229, 229);
}

.popup-dialog.bottom-pointer.right-pointer:before {
	top: unset;
	bottom: -21px;
	border-color: rgb(229, 229, 229) rgb(229, 229, 229) transparent transparent;
}

.popup-dialog.gray {
	background-color: rgb(219, 219, 219);
	border-color: transparent rgb(219, 219, 219) rgb(219, 219, 219)
		rgb(219, 219, 219);
}

.popup-dialog.gray:before {
	border-color: transparent transparent rgb(219, 219, 219) rgb(219, 219, 219);
}

/* END POINTED POPUP*/

/* ===== MODAL STYLES ===== */

.modal.show {
	display: block;
}

.modal.double-top-margin .modal-dialog {
	margin-top: 3.5rem;
}

.modal.triple-top-margin .modal-dialog {
	margin-top: 5.25rem;
}

.modal.half-width .modal-dialog {
	width: 25%;
}

.modal.show .modal-dialog {
	max-width: 50%;
	animation: modalSlideDown 0.3s ease-in-out;
}

.modal.show.no-animation .modal-dialog {
	animation: none;
}

.modal .modal-content {
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

@keyframes modalSlideDown {
	0% {
		margin-top: 0px;
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

/* ======================== */

/* ===== FILTERABLE SELECT ===== */
.filterable-select {
	position: relative;
}

.filterable-select .default-datalist-items,
.filterable-select ul {
	max-height: 390px; /* Enough to show 10 rows */
	overflow-y: auto;
	z-index: 1000;
	border: 1px solid lightgray;
	border-top: none;
	border-radius: 3px;
	padding: 0px;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25);
	position: absolute;
	width: 100%;
}

.filterable-select.grow-upwards ul {
	bottom: 1rem;
}

.filterable-select .items {
	position: absolute;
	background-color: white;
	z-index: 3;
	max-height: 300px;
	overflow-y: scroll;
	width: 100%;
	min-width: 175px;
	max-width: 230px;
	border: 1px solid rgb(150, 200, 240);
	/*border-bottom: 1px solid rgb(240, 240, 240);*/
}

.filterable-select .select-item,
.filterable-select .react-datalist-input__container li {
	display: block;
	padding: 8px;
	cursor: pointer;
	z-index: 1001;
	background-color: white;
	font-size: 1em;
}

.filterable-select .select-item:hover,
.filterable-select li:hover {
	background-color: rgb(240, 245, 240);
}

/* === END FILTERABLE SELECT === */

.my-col-1 {
	width: 5%;
	padding: 0px 7px;
}

.my-col-2 {
	width: 10%;
	padding: 0px 7px;
}

.my-col-3 {
	width: 15%;
	padding: 0px 7px;
}

.my-col-4 {
	width: 20%;
	padding: 0px 7px;
}

.my-col-5 {
	width: 25%;
	padding: 0px 7px;
}

.my-col-6 {
	width: 30%;
	padding: 0px 7px;
}

.my-col-7 {
	width: 35%;
	padding: 0px 7px;
}

.my-col-8 {
	width: 40%;
	padding: 0px 7px;
}

.my-col-9 {
	width: 45%;
	padding: 0px 7px;
}

.my-col-10 {
	width: 50%;
	padding: 0px 7px;
}

.my-col-11 {
	width: 55%;
	padding: 0px 7px;
}

.my-col-12 {
	width: 60%;
	padding: 0px 7px;
}

.my-col-13 {
	width: 65%;
	padding: 0px 7px;
}

.my-col-14 {
	width: 70%;
	padding: 0px 7px;
}

.my-col-15 {
	width: 75%;
	padding: 0px 7px;
}

.my-col-16 {
	width: 80%;
	padding: 0px 7px;
}

.my-col-17 {
	width: 85%;
	padding: 0px 7px;
}

.my-col-18 {
	width: 90%;
	padding: 0px 7px;
}

.my-col-19 {
	width: 95%;
	padding: 0px 7px;
}

.my-col-20 {
	width: 100%;
	padding: 0px 7px;
}

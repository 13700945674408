.config-content {

}

    .config-content .config-tile {
        background-color: rgb(235, 235, 235);
        border-radius: 5px;
        margin: 8px 0px;
        padding: 15px 10px;
    }

    .config-content h5 {
        font-size: 1.1rem;
    }

#employee-select {
    width: 300px;
    top: -3px;    
}
    
    #employee-select input {
        height: 2.5em;
    }
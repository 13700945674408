
.timesheet {
    margin-bottom: 20px;
}

#top-level-controls {
    display: flex;
    line-height: 2;
}

    #top-level-controls > * {
        margin-right: 12px;
    }

    #top-level-controls .filterable-select, #top-level-controls input {
        width: 300px;
    }

    #top-level-controls .filterable-select input {
        height: 2rem;
    }

    #top-level-controls .filterable-select > span {
        top: 2px !important;
    }

#top-level-info {
    display: flex;
    border: 1px solid rgb(140, 140, 140);
    border-radius: 5px;
    width: 1000px;
    margin-bottom: 20px;
}

    #top-level-info > div {
        width: 500px;        
    }

    #top-level-info > div:first-of-type {
        border-right: 1px solid rgb(150, 150, 150);
    }

        #top-level-info .info-row {
            padding: 4px 7px;
            height: 2.5em;
            line-height: 1.8;
            border-bottom: 1px solid lightgray;
        }

            #top-level-info > div:first-of-type .info-row:last-of-type {
                border-bottom: none;
            }

        #top-level-info .info-row b {
            display: inline-block;
            width: 120px !important;
        }

.timesheet-header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 5px 0px;
}

    .timesheet-header b {
        padding-left: 4px;
    }

    .timesheet-header .btn {
        height: 2.5em;
        align-self: flex-end;
    }

.timesheet-body {
    overflow-y: auto;
    max-height: calc(100vh - 390px);
}

/* ===== LINE ITEM ===== */

.timesheet-line {
    display: flex;
    width: 100%;
    border-bottom: 1px solid lightgray;
    padding: 5px 0px;
    font-size: 13px;
}

    .timesheet-line:hover {
        background-color: rgba(50, 200, 120, 0.2) !important;
    }

    .timesheet-line:nth-of-type(2n+1) {
        background-color: rgb(240, 240, 240);
    }

/* ===================== */

/* ===== ENTRY ===== */

.timesheet-entry .popup-dialog {
    width: 350px;
}

/*.timesheet-entry input:first-of-type,*/
.timesheet-entry div:first-of-type {
    /*background-color: white;*/
    cursor: pointer !important;
}

.timesheet-entry .default-element {
    line-height: 1.8;
}

.timesheet-entry input.locked,
.timesheet-entry div.locked {
    background-color: #e9ecef
}

.timesheet-entry input.weekend:first-of-type,
.timesheet-entry div.weekend:first-of-type {
    background-color: rgb(225, 225, 225) !important;
}

/* ================= */

.create-timesheet-modal .modal-dialog {
    width: 500px;
}

.create-timesheet-modal .modal-body > div {
    margin: 8px 0px; 
    line-height: 2;
}

.create-timesheet-modal .modal-body > div > :nth-child(2n) {
    width: calc(100% - 150px);
}